<style lang="scss" scoped>
.page-analysis-imports {
    /deep/ .upload-box {
        width: 100%;
        .el-upload { 
            width: 100%;
        }
        .el-upload-dragger {
            width: 100% !important;
        }
        .upload-side {
            width: 100%;
            height: 100%;
        }
    }
}
</style>

<template>
    <div class="page-analysis-imports">
        <el-row :gutter="10">
            <el-col :xs="24">
                <el-card class="mrgb20">
                    <el-alert class="mrgb10" type="info" title="注：批量文件上传，请确认选择的服务区，车辆数据和车辆统计数据会默认生成日期!" :closable="false"></el-alert>
                    <el-upload :ref="refMulUpload" class="dib" :action="uploadMulUrl" :before-upload="beforeMulUpload" accept=".xlsx,.xls"
                        :on-change="handleMulChange" :show-file-list="false" multiple :auto-upload="false">
                        <el-button type="primary" size="medium" @click.stop="clickMulUpload"><i class="fa fa-sign-in"></i> 选择批量上传文件</el-button>
                    </el-upload>
                    <el-button class="mrgl5" type="danger" size="medium" @click="showDeleteDrawer()"><i class="fa fa-trash"></i> 删除上传数据</el-button>
                    <el-button v-if="uploadTables.length > 0" class="mrgr5 fr" type="danger" size="medium" @click="submitMul()"><i class="fa fa-upload"></i> 确认上传</el-button>
                    <el-button v-if="uploadTables.length > 0" class="mrgr5 fr" type="warning" plain size="medium" @click="clearMulData()"><i class="fa fa-trash"></i> 清空批量数据</el-button>
                    <div class="clearb"></div>

                    <el-table class="mrgt20" :ref="refTables" :data="uploadTables" border v-if="uploadTables.length > 0">
                        <el-table-column label="服务区" prop="clientArea" width="120"></el-table-column>
                        <el-table-column label="日期" width="250">
                            <template slot-scope="scope">
                                <el-date-picker v-model="uploadClientDates[scope.$index]" type="date" placeholder="选择日期" size="mini" 
                                    v-show="scope.row.clientIsShowPicker" @change="(v) => { changeMulDate(v, scope.row) }"
                                    :picker-options="pickerOptions"></el-date-picker>
                            </template>
                        </el-table-column>
                        <el-table-column label="名称" prop="name"></el-table-column>
                        <el-table-column label="上传状态" prop="clientState">
                            <template slot-scope="scope">
                                <span class="col_success" v-if="scope.row.clientState === 1">{{ scope.row.clientStateDesc }}</span>
                                <span class="col_danger" v-else-if="scope.row.clientState === -1">{{ scope.row.clientStateDesc }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" width="100">
                            <template slot-scope="scope">
                                <el-button type="danger" size="mini" plain @click="_removeMul(scope.row, scope.$index)">移除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-card>
            </el-col>
            <el-col :xs="24">
                <el-divider><i class="fa fa-long-arrow-down"></i> 单文件上传</el-divider>
            </el-col>
            <el-col :xs="24">
                <el-card class="mrgb20">
                    <el-alert class="mrgb10" type="warning" title="注：单文件上传，请确认选择的服务区，若上传车辆数据和车辆统计数据请先选择日期!" :closable="false"></el-alert>
                    <span class="mrgl20">日期: </span>
                    <el-date-picker v-model="selectDate" type="date" placeholder="选择日期" size="medium" :picker-options="pickerOptions"></el-date-picker>
                    <el-button class="mrgl10" type="info" plain size="medium" @click="quickDate()"
                        v-if="historyDate">上次选择日期：{{ historyDate }}</el-button>
                    <div class="clearb"></div>
                </el-card>
            </el-col>
            <el-col :xs="24" :sm="12" :lg="8">
                <el-card class="mrgb15">
                    <div class="f16 fwb mrgb15">
                        <span>{{ dataNames[0] }}</span>
                        <span class="fr f13 fwn mrgt2 col_primary" v-if="lastTimeItem.dailyData">
                            {{ lastTimeItem.dailyData }}
                            <el-tooltip effect="dark" :content="'已导入的最近' + dataNames[0] + '的日期'" placement="right-start">
                                <i class="el-icon-question f16"></i>
                            </el-tooltip>
                        </span>
                    </div>
                    <el-upload :ref="refUpload[0]" class="upload-box" drag :action="uploadMulUrl" :headers="uploadHeaders" :before-upload="(v) => { beforeUpload(v, dataNames[0]) }"
                        :on-success="(v) => { importDataSuccess(v, dataNames[0]) }" :on-error="(v) => { importDataError(v, dataNames[0]) }" :data="uploadData" 
                        :show-file-list="false" :on-change="(v) => { handleChange(v, 0) }" :auto-upload="false" accept=".xlsx,.xls">
                        <div class="upload-side" @click.stop="clickUpload(0)">
                            <i class="el-icon-upload"></i>
                            <div class="el-upload__text">将{{ dataNames[0] }}文件拖到此处，或<em>点击上传</em></div>
                        </div>
                    </el-upload>
                </el-card>
            </el-col>
            <el-col :xs="24" :sm="12" :lg="8">
                <el-card class="mrgb15">
                    <div class="f16 fwb mrgb15">
                        <span>{{ dataNames[1] }}</span>
                        <span class="fr f13 fwn mrgt2 col_primary" v-if="lastTimeItem.order">
                            {{ lastTimeItem.order }}
                            <el-tooltip effect="dark" :content="'已导入的最近' + dataNames[1] + '的日期'" placement="right-start">
                                <i class="el-icon-question f16"></i>
                            </el-tooltip>
                        </span>
                    </div>
                    <el-upload :ref="refUpload[1]" class="upload-box" drag :action="uploadMulUrl" :headers="uploadHeaders" :before-upload="(v) => { beforeUpload(v, dataNames[1]) }" 
                        :on-success="(v) => { importDataSuccess(v, dataNames[1]) }" :on-error="(v) => { importDataError(v, dataNames[1]) }" :data="uploadData" 
                        :show-file-list="false" :on-change="(v) => { handleChange(v, 1) }" :auto-upload="false" accept=".xlsx,.xls">
                        <div class="upload-side" @click.stop="clickUpload(1)">
                            <i class="el-icon-upload"></i>
                            <div class="el-upload__text">将{{ dataNames[1] }}文件拖到此处，或<em>点击上传</em></div>
                        </div>
                    </el-upload>
                </el-card>
            </el-col>
            <el-col :xs="24" :sm="12" :lg="8">
                <el-card class="mrgb15">
                    <div class="f16 fwb mrgb15">
                        <span>{{ dataNames[2] }}</span>
                        <span class="fr f13 fwn mrgt2 col_primary" v-if="lastTimeItem.user">
                            {{ lastTimeItem.user }}
                            <el-tooltip effect="dark" :content="'已导入的最近' + dataNames[2] + '的日期'" placement="right-start">
                                <i class="el-icon-question f16"></i>
                            </el-tooltip>
                        </span>
                    </div>
                    <el-upload :ref="refUpload[2]" class="upload-box" drag :action="uploadMulUrl" :headers="uploadHeaders" :before-upload="(v) => { beforeUpload(v, dataNames[2]) }"
                        :on-success="(v) => { importDataSuccess(v, dataNames[2]) }" :on-error="(v) => { importDataError(v, dataNames[2]) }" :data="uploadData" 
                        :show-file-list="false" :on-change="(v) => { handleChange(v, 2) }" :auto-upload="false" accept=".xlsx,.xls">
                        <div class="upload-side" @click.stop="clickUpload(2)">
                            <i class="el-icon-upload"></i>
                            <div class="el-upload__text">将{{ dataNames[2] }}文件拖到此处，或<em>点击上传</em></div>
                        </div>
                    </el-upload>
                </el-card>
            </el-col>
            <el-col :xs="24" :sm="12" :lg="8">
                <el-card class="mrgb15">
                    <div class="f16 fwb mrgb15">
                        <span>{{ dataNames[3] }}</span>
                        <span class="fr f13 fwn mrgt2 col_primary" v-if="lastTimeItem.vehicle">
                            {{ lastTimeItem.vehicle }}
                            <el-tooltip effect="dark" :content="'已导入的最近' + dataNames[3] + '的日期'" placement="right-start">
                                <i class="el-icon-question f16"></i>
                            </el-tooltip>
                        </span>
                    </div>
                    <el-upload :ref="refUpload[3]" class="upload-box" drag :action="uploadMulUrl" :headers="uploadHeaders" :before-upload="(v) => { beforeUpload(v, dataNames[3]) }"
                        :on-success="(v) => { importDataSuccess(v, dataNames[3]) }" :on-error="(v) => { importDataError(v, dataNames[3]) }" :data="uploadData" 
                        :show-file-list="false" :on-change="(v) => { handleChange(v, 3) }" :auto-upload="false" accept=".xlsx,.xls">
                        <div class="upload-side" @click.stop="clickUpload(3)">
                            <i class="el-icon-upload"></i>
                            <div class="el-upload__text">将{{ dataNames[3] }}文件拖到此处，或<em>点击上传</em></div>
                            <div class="col_danger tl tin1">1、导入前请先选择日期</div>
                            <div class="col_danger tl tin1">2、已下架和其他状态的车辆数据，请分开导入</div>
                        </div>
                    </el-upload>
                </el-card>
            </el-col>
            <el-col :xs="24" :sm="12" :lg="8">
                <el-card class="mrgb15">
                    <div class="f16 fwb mrgb15">
                        <span>{{ dataNames[4] }}</span>
                        <span class="fr f13 fwn mrgt2 col_primary" v-if="lastTimeItem.vehicleDaily">
                            {{ lastTimeItem.vehicleDaily }}
                            <el-tooltip effect="dark" :content="'已导入的最近' + dataNames[4] + '的日期'" placement="right-start">
                                <i class="el-icon-question f16"></i>
                            </el-tooltip>
                        </span>
                    </div>
                    <el-upload :ref="refUpload[4]" class="upload-box" drag :action="uploadMulUrl" :headers="uploadHeaders" :before-upload="(v) => { beforeUpload(v, dataNames[4]) }"
                        :on-success="(v) => { importDataSuccess(v, dataNames[4]) }" :on-error="(v) => { importDataError(v, dataNames[4]) }" :data="uploadData" 
                        :show-file-list="false" :on-change="(v) => { handleChange(v, 4) }" :auto-upload="false" accept=".xlsx,.xls">
                        <div class="upload-side" @click.stop="clickUpload(4)">
                            <i class="el-icon-upload"></i>
                            <div class="el-upload__text">将{{ dataNames[4] }}文件拖到此处，或<em>点击上传</em></div>
                            <div class="col_danger tl tin1">注：导入前请先选择日期</div>
                        </div>
                    </el-upload>
                </el-card>
            </el-col>
            <el-col :xs="24" :sm="12" :lg="8">
                <el-card class="mrgb15">
                    <div class="f16 fwb mrgb15">
                        <span>{{ dataNames[5] }}</span>
                        <span class="fr f13 fwn mrgt2 col_primary" v-if="lastTimeItem.wallet">
                            {{ lastTimeItem.wallet }}
                            <el-tooltip effect="dark" :content="'已导入的最近' + dataNames[5] + '的日期'" placement="right-start">
                                <i class="el-icon-question f16"></i>
                            </el-tooltip>
                        </span>
                    </div>
                    <el-upload :ref="refUpload[5]" class="upload-box" drag :action="uploadMulUrl" :headers="uploadHeaders" :before-upload="(v) => { beforeUpload(v, dataNames[5]) }"
                        :on-success="(v, type) => { importDataSuccess(v, dataNames[5]) }" :on-error="(v, type) => { importDataError(v, dataNames[5]) }" :data="uploadData" 
                        :show-file-list="false" :on-change="(v) => { handleChange(v, 5) }" :auto-upload="false" accept=".xlsx,.xls">
                        <div class="upload-side" @click.stop="clickUpload(5)">
                            <i class="el-icon-upload"></i>
                            <div class="el-upload__text">将{{ dataNames[5] }}文件拖到此处，或<em>点击上传</em></div>
                        </div>
                    </el-upload>
                </el-card>
            </el-col>
        </el-row>
        <!--删除上传数据-->
        <delete-import :ref="refDelete" @refreshData="getLatestDate"></delete-import>
    </div>
</template>

<script>
import configMixins from "@/mixins/config"
import * as funReport from "@/api/report"
import moment from "moment"
import DeleteImport from "./components/deleteImportData"
export default {
    name: "pageAnalysisImports",
    components: { DeleteImport },
    mixins: [configMixins],
    data() {
        return {
            uploadMulUrl: window.$common.appBaseApi() + "/api/app/base-data/import",
            refTables: "refTables",
            refMulUpload: "refMulUpload",
            refDelete: "refDeleteDrawer",
            uploadTables: [],
            uploadClientDates: [],
            uploadHeaders: window.$common.appHeaders(),
            selectDate: "",
            uploadData: {
                area: ""
            },
            refUpload: ["DailyRecord", "OrderRecord", "UserRecord", "VehicleRecord", "VehicleDailyRecord", "WalletRecord"],
            dataNames: ["每日统计数据", "订单数据", "用户数据", "车辆数据", "车辆统计数据", "钱包数据"],
            types: ["DailyRecord", "", "", "", "", "", ""],
            historyDate: "", // 记录上次导入日期
            lastTimeItem: {
                dailyData: "",
                order: "",
                user: "",
                vehicle: "",
                vehicleDaily: "",
                wallet: ""
            },
            mulSetTimeout: null,
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now()
                }
            }
        }
    },
    watch: {
        "$store.getters.serviceArea"(v) {
            this.getLatestDate()
            this.uploadTables = this.uploadTables.map(x => {
                x.clientArea = this.$store.getters.serviceArea
                return x
            })
        }
    },
    mounted() {
        this.getLatestDate()
    },
    methods: {
        getLatestDate() {
            funReport.GetLastImportTime({ area: this.$store.getters.serviceArea }).then(res => {
                if (res) {
                    this.lastTimeItem.dailyData = moment(res.dailyData).isValid() ? moment(res.dailyData).format("YYYY-MM-DD") : ""
                    this.lastTimeItem.order = moment(res.order).isValid() ? moment(res.order).format("YYYY-MM-DD") : ""
                    this.lastTimeItem.user = moment(res.user).isValid() ? moment(res.user).format("YYYY-MM-DD") : ""
                    this.lastTimeItem.vehicle = moment(res.vehicle).isValid() ? moment(res.vehicle).format("YYYY-MM-DD") : ""
                    this.lastTimeItem.vehicleDaily = moment(res.vehicleDaily).isValid() ? moment(res.vehicleDaily).format("YYYY-MM-DD") : ""
                    this.lastTimeItem.wallet = moment(res.wallet).isValid() ? moment(res.wallet).format("YYYY-MM-DD") : ""
                }
            })
        },
        // 批量上传文件
        clickMulUpload() {
            console.log(this.uploadTables)
            let _fun = () => {
                this.uploadTables = []
                let isUpload = this.beforeMulUpload()
                if (isUpload) {
                    this.$refs[this.refMulUpload].$refs["upload-inner"].handleClick()
                }
            }
            if (this.uploadTables.length > 0) {
                this.confirm("重新选择文件会清空之前的选择，是否确定?").then(() => {
                    _fun()
                })
            } else {
                _fun()
            }
            
        },
        // 上传之前简单验证
        beforeMulUpload() {
            if (!this.$store.getters.serviceArea) {
                this.warningMsg("请先选择服务区!")
                return false
            }
            this.uploadData = {
                area: this.$store.getters.serviceArea
            }
            return true
        },
        // 批量选择文件后生成上传数据
        handleMulChange(file) {
            if (file.status == "ready") {
                this.uploadTables.push(file)
                // 延迟执行数据操作，避免多次加载
                if (!this.mulSetTimeout) {
                    window.$common.fullLoading()
                    this.mulSetTimeout = setTimeout(() => {
                        // 生成上传的数据并执行上传
                        let commitData = new FormData()
                        this.uploadTables.forEach(item => {
                            commitData.append("files", item.raw)
                        })
                        funReport.GetImportTypes(commitData).then(types => {
                            window.$common.closeFullLoading()
                            this.uploadTables = this.uploadTables.map(x => {
                                let titem = types.find(m => m.fileName === x.name)
                                x.clientArea = this.$store.getters.serviceArea
                                x.clientState = null
                                x.clientStateDesc = ""
                                x.clientIsShowPicker = false
                                x.clientImportType = titem.importType
                                let date = ""
                                if (titem) {
                                    if (titem.importType === this.refUpload[3]) {
                                        x.clientIsShowPicker = true
                                        date = moment().format("YYYY-MM-DD")
                                    } else if (titem.importType === this.refUpload[4]) {
                                        x.clientIsShowPicker = true
                                        date = moment().subtract(1, "days").format("YYYY-MM-DD")
                                    }
                                }
                                x.clientDate = date
                                return x
                            })
                            this.uploadClientDates = this.uploadTables.map(x => {
                                return x.clientDate
                            })
                            // 执行完查询就清空延迟
                            this._clearMulSetTimeout()
                        }).catch(e => {
                            window.$common.closeFullLoading()
                            // 执行完查询就清空延迟
                            this._clearMulSetTimeout()
                        })
                    }, 1000)
                }
            }
        },
        // 日期选择
        changeMulDate(v, row) {
            row.clientDate = moment(v).format("YYYY-MM-DD")
            console.log(this.uploadTables)
        },
        // 移除当前选择
        _removeMul(row, index) {
            this.uploadClientDates.splice(index, 1)
            this.uploadTables.splice(index, 1)
        },
        _clearMulSetTimeout() {
            this.mulSetTimeout = null
        },
        // 清空批量数据
        clearMulData() {
            this.confirm("确认清空批量导入的数据吗?").then(() => {
                this.uploadTables = []
                this.$refs[this.refMulUpload].clearFiles()
            })
        },
        // 确认上传
        submitMul() {
            let isExcel = true
            let isVehicleDailyDate = true
            for(let i in this.uploadTables) {
                let item = this.uploadTables[i]
                if (isExcel && item.name.indexOf(".xls") === -1) {
                    isExcel = false
                }
                if (isVehicleDailyDate && item.clientImportType === this.refUpload[4] && !this._isVehicleDailyByTitleDate(item.name, item.clientDate)) {
                    isVehicleDailyDate = false
                }
            }
            if (!isExcel) {
                this.errorMsg("只能上传Excel文件!")
                return false
            }
            if (!isVehicleDailyDate) {
                this.warningMsg("车辆统计表日期不匹配!")
                return false
            }
            this.confirm(`<span class="col_danger">当前服务区：${this.$store.getters.serviceArea}</span><br />导入数据将会逐个进行上传，上传过程中请勿刷新浏览器或者跳转界面，请耐心等待!`, "上传确认?", { dangerouslyUseHTMLString: true }).then(() => {
                this.uploadTables = this.uploadTables.map(x => {
                    x.clientState = null
                    x.clientStateDesc = ""
                    return x
                })
                window.$common.fullLoading()
                setTimeout(() => {
                    this.submitFiles()
                }, 1000)
            })
        },
        // 执行批量上传，并返回状态
        submitFiles(count) {
            count = !count ? 0 : count
            if (count >= this.uploadTables.length) {
                return this.uploadTables.length
            }
            let item = this.uploadTables[count]
            let commitData = new FormData()
            if (item.clientDate) {
                commitData.append("date", item.clientDate)
            }
            commitData.append("area", item.clientArea)
            commitData.append("file", item.raw)
            funReport.UploadImportData(commitData, item.clientArea).then(res => {
                let temp = this.uploadTables[count]
                temp.clientState = 1
                temp.clientStateDesc = "成功"
                this.$set(this.uploadTables, count, temp)
                count++
                this.commonSubmit(count)
            }).catch(error => {
                let temp = this.uploadTables[count]
                temp.clientState = -1
                temp.clientStateDesc = error && error.response && error.response.data && error.response.data.error && error.response.data.error.message || "失败"
                this.$set(this.uploadTables, count, temp)
                count++
                this.commonSubmit(count)
            })
        },
        commonSubmit(count) {
            if (count >= this.uploadTables.length) {
                this.getLatestDate()
                window.$common.closeFullLoading()
            }
            setTimeout(() => {
                this.submitFiles(count)
            }, 200)
        },

        // 根据车辆统计表的时间初步判断是否可以导入
        _isVehicleDailyByTitleDate(fileName, date) {
            let result = true
            date = moment(date).add(1, "days").format("YYYY-MM-DD")
            if (fileName.indexOf(date) === -1) {
                result = false
            }
            return result
        },

        /**
         * 单个文件上传
         */
        clickUpload(index) {
            let isUpload = this.beforeUpload(null, this.dataNames[index])
            if (isUpload) {
                this.$refs[this.refUpload[index]].$refs["upload-inner"].handleClick()
            }
        },
        beforeUpload(file, name) {
            if (!this.$store.getters.serviceArea) {
                this.warningMsg("请先选择服务区!")
                return false
            }
            this.uploadData = {
                area: this.$store.getters.serviceArea
            }
            // 判断需要选择日期
            if (name === this.dataNames[3] || name === this.dataNames[4]) {
                if (!this.selectDate) {
                    this.warningMsg(`导入${name}需要选择日期!`)
                    return false
                } else {
                    this.uploadData.date = moment(this.selectDate).format("YYYY-MM-DD")
                }
            }
            return true
        },
        // 选择文件后
        handleChange(file, index) {
            if (file.status == "ready") {
                if (file && file.name.indexOf(".xls") === -1) {
                    this.warningMsg("只能上传Excel文件!")
                    this.cannotUpload(index)
                    return false
                }
                // 判断车辆统计时间是否匹配
                if (index === 4 && !this._isVehicleDailyByTitleDate(file.name, this.uploadData.date)) {
                    this.warningMsg("车辆统计表日期不匹配!")
                    this.cannotUpload(index)
                    return false
                }
                // 去后台验证与当前选择文件类型是否符合
                let commitData = new FormData()
                commitData.append("files", file.raw)
                window.$common.fullLoading()
                funReport.GetImportTypes(commitData).then(types => {
                    if (types && types[0] && file.name === types[0].fileName) {
                        let type = types[0].importType
                        // 类型符合则自动提交上传
                        if (this.refUpload[index] === type) {
                            this.$refs[this.refUpload[index]].submit()
                        } else {
                            this.cannotUpload(index)
                            this.warningMsg("上传文件类型不符!")
                            return false
                        }
                    } else {
                        this.cannotUpload(index)
                        this.warningMsg("与选择的文件类型不符!")
                        return false
                    }
                }).catch(e => {
                    this.cannotUpload(index)
                })
            } else {
                this.cannotUpload(index)
                return false
            }
        },
        cannotUpload(index) {
            /* let content = `服务区：${this.$store.getters.serviceArea}<br />文件名：${file.name}`
            if (index === 3 || index === 4) {
                content += "<br />导入日期：" + this.uploadData.date
            }
            this.confirm(content, "上传确认?", { dangerouslyUseHTMLString: true }).then(() => {
                window.$common.fullLoading()
                this.$refs[this.refUpload[index]].submit()
            }).catch(() => {
                this.$refs[this.refUpload[index]].clearFiles()
            }) */
            this.$refs[this.refUpload[index]].clearFiles()
            window.$common.closeFullLoading()
        },
        // 导入数据成功
        importDataSuccess(res, name) {
            window.$common.closeFullLoading()
            if (res) {
                this.getLatestDate()
                this.successMsg(`${name}${res}`)
                // 时间重置，并生成历史时间
                if (name === this.dataNames[3] || name === this.dataNames[4]) {
                    this.historyDate = this.uploadData.date
                }
                this.selectDate = ""
            }
        },
        // 导入数据失败
        importDataError(err, name) {
            window.$common.closeFullLoading()
            if (err && err.message) {
                if (name === this.dataNames[3] || name === this.dataNames[4]) {
                    this.historyDate = this.uploadData.date
                }
                this.selectDate = ""
                // 失败的提示
                let msg = ""
                let dmsg = name + "导入失败，可能数据错误或存在重复数据!"
                try {
                    let errItem = JSON.parse(err.message)
                    msg = errItem && errItem.error && errItem.error.message || dmsg
                } catch(ex) {
                    console.error(msg)
                }
                this.errorMsg(msg)
            }
        },
        quickDate() {
            if (this.historyDate) {
                this.selectDate = moment(this.historyDate)
                this.historyDate = ""
            }
        },
        showDeleteDrawer() {
            this.$refs[this.refDelete].open(this.refUpload, this.dataNames)
        }
    }
}
</script>